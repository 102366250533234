<template>
<b-modal id="modalbasic" v-b-modal.modal-center ref="modalbasic" @hide="close()" v-model="isVisible" @cancel="close()" @close="close()" :no-close-on-backdrop="true" size="xl">
  <template #modal-title>
    <br>
    <h1>Configure Notifications</h1>
  </template>
  <Messages />
  <b-row>
    <b-colxx xxs="12">
      <b-card class="mb-4" style="max-height: 25rem; overflow: scroll;">

        <b-overlay :show="processing" variant="transparent" blur="5px" opacity="1">
          <vuetable ref="vuetable" :api-mode="false" :fields="fields" :data-total="dataCount" :per-page="perPage" :css="css.table" :row-class="onRowClass" :data-manager="dataManager">
            <template slot="notificationToggle" slot-scope="props">
              <div style="display:flex;justify-content:center;align-items;center;">
                <ToggleSwitch v-model="props.rowData.notificationEnabled" :id="'toggle-' + props.notificationTypeID" :emitOnMount="false" theme="custom" class="vue-switcher-small" color="primary" v-on:input.self="onNotificationToggleChange" />
              </div>
            </template>
          </vuetable>
        </b-overlay>
      </b-card>

    </b-colxx>
  </b-row>
</b-modal>
</template>

<script>
import CssTableConfig from '../../constants/VuetableBootstrapconfig'
import NotificationMixin from '../../mixins/NotificationMixin.vue'
import {
  mapMutations
} from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import Switches from 'vue-switches'
import ModalMessages from '../../containers/navs/ModalMessages.vue'

export default {
  components: {
    vuetable: Vuetable,
    ToggleSwitch: Switches,
    Messages: ModalMessages

  },
  mixins: [NotificationMixin],
  data () {
    var _idfield = 'employeeID'
    return {
      notificationConfigureData: [],
      perPage: 100,
      searchFor: '',
      from: 0,
      to: 0,
      dataCount: 0,
      isVisible: false,
      isProcessing: false,
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: 'notificationType',
          sortField: 'notificationType',
          title: 'Notification Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '33%'
        },
        {
          name: '__slot:notificationToggle',
          sortField: 'notificationToggle',
          title: 'Active',
          titleClass: '',
          dataClass: 'text-muted',
          width: '33%',
          cell: 'notificationToggle'
        }
      ]
    }
  },
  watch: {
    notificationConfigureData (newVal, oldVal) {
      this.$refs.vuetable.refresh()
    }
  },
  async created () {
    await this.refreshTableData(false)
  },
  mounted () {
    this.$refs.vuetable.setData(this.notificationConfigureData)
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),

    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },

    dataManager (sortOrder, pagination) {
      let data = this.notificationConfigureData

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction)
      }

      // Since the filter might affect the total number of records
      // we can ask vuetable to recalculate the pagination for us
      // by calling makePagination(). This will make VuetablePagination
      // work just like in API mode

      // pagination = this.$refs.vuetable.makePagination(data.length)

      // If you don't want to use pagination component, you can just
      // return the data array
      return {
        data: data
      }
    },
    // Component-specific Vue Table methods

    async refreshTableData (loading) {
      // Have to set gridLoading in this call since data mode never fires loading or loaded events
      if (loading) {
        this.gridLoading = true
      }
      await this.getNotificationConfigureData()
        .then(response => {
          this.notificationConfigureData = response
          this.perPage = this.notificationConfigureData.length
          this.from = 1
          this.to = this.notificationConfigureData.length
          this.dataCount = this.notificationConfigureData.length
          this.isProcessing = false
          this.$refs.vuetable.setData(this.notificationConfigureData)
          this.gridLoading = false
        })
    },

    // End of Component-specific Vue Table methods
    async onNotificationToggleChange () {
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      const employeeID = localStorage.getItem('employeeID')
      if (employeeID) {
        await this.setNotificationConfigure({
          employeeID: employeeID,
          notificationConfiguration: this.notificationConfigureData
        }).then(() => {
          this.refreshTableData()
        })
      }
    },

    // Component-specific Methods
    async show (opts = {}) {
      this.title = opts.title

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
    },
    close () {
      this.searchFor = ''
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    }
  }

  // End of Component-specific methods
}
</script>

<template>
<b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
        <!--<b-card class="auth-card" no-body>
            <div class="position-relative image-side">
                <p class="text-white h2">{{ $t('dashboards.magic-is-in-the-details') }}</p>
                <p class="white mb-0">
                    Please use your credentials to login.
                    <br />If you are not a member, please
                    <router-link tag="a" to="/user/register" class="white">register</router-link>.
                </p>
            </div>
            <div class="form-side">
                <router-link tag="a" to="/">
                    <span class="logo-single" />
                </router-link>
                <h6 class="mb-4">{{ $t('user.login-title')}}</h6>
                        <b-button type="submit" variant="primary" size="lg" :disabled="processing" :class="{'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing,
                    'show-success': !processing && loginError===false,
                    'show-fail': !processing && loginError }">
                        </b-button>
            </div>
        </b-card>-->
    </b-colxx>
</b-row>
</template>

<script>
// import { PublicClientApplication } from '@azure/msal-browser'
// import { msalConfig } from '../../utils'

import {
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex'

import { adminRoot } from '../../constants/config'

export default {
  data () {
    return {
      account: undefined,
      signin: 'https://microsoft.com'
    }
  },
  async mounted () {
    // this.$msalInstance = new PublicClientApplication(msalConfig)

    // this.setLogout()

    // get the users logged in accounts in our azure ad tenant
    var account = this.currentUser

    // if the user has a logged in account redirect them to the start of the application
    if (account != null) {
      this.$router.push(adminRoot)
    } else {
      // send the path that the user is trying to navigate to with the login method
      // so that on successful login the app will redirect them
      await this.login(this.$route.query.to)
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'processing', 'loginError', 'AzureLocalID', 'loggedin'])
  },
  methods: {
    ...mapActions(['login', 'signOut']),
    ...mapMutations(['setLogout'])
  },
  watch: {
    loggedin (val) {
      if (val) {
        setTimeout(() => {
          this.$router.push(adminRoot)
        }, 200)
      }
    },
    loginError (val) {
      if (val != null) {
        this.$notify('error', 'Login Error', val, {
          duration: 10000,
          permanent: false
        })
      }
    }
  }
}
</script>

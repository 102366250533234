<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config.js'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      statusCode: null,
      notifications: [],
      notification: {},
      notificationConfigureData: [],
      processing: false
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage',
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async getEmployeeNotifications () {
      await axios
        .get(`${BeaconapiUrl}/api/notification/${localStorage.getItem('employeeID')}`)
        .then((result) => {
          this.statusCode = result.status
          this.notifications = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateNotification (notification, status) {
      if (status === 'read') {
        notification.active = false
      } else {
        notification.dateAcknowledged = null
        notification.active = true
      }

      await axios
        .put(`${BeaconapiUrl}/api/notification`, notification)
        .then((result) => {
          this.statusCode = result.status
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async acknowledgeAllNotificationsForUser (employeeID) {
      await axios
        .patch(`${BeaconapiUrl}/api/notification/${employeeID}/all`)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async createNotification (notification) {
      await instance
        .post(`${BeaconapiUrl}/api/notification`, notification)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getNotificationConfigureData () {
      this.processing = true
      const employeeID = localStorage.getItem('employeeID')
      return await axios
        .get(`${BeaconapiUrl}/api/notification/horizon/${employeeID}`)
        .then((result) => {
          this.statusCode = result.status
          this.notificationConfigureData = result.data
          this.processing = false
          return result.data.sort(
            (a, b) => parseFloat(a.notificationTypeID) - parseFloat(b.notificationTypeID)
          )
        })
        .catch((error) => {
          this.processing = false
          this.setModalDangerMessage(error)
        })
    },
    async setNotificationConfigure (notification) {
      await axios
        .put(`${BeaconapiUrl}/api/notification/configuration`, notification)
        .then((result) => {
          this.statusCode = result.status
          this.setModalInfoMessage('Notification configured successfully')
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    }
  }
}
</script>

<template>
  <div>
    <Confirmation ref="Confirmation"></Confirmation>
    <datatable-heading
      ref="DatatableHeading"
      :title="$t('notifications.my-notifications')"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange">
      <template v-slot:button>
        <b-button
            class="ml-2"
            variant="primary"
            size="lg"
            @click="notificationConfigure"
            >
            Configure
          </b-button>
        <b-button
          class="ml-2"
          v-b-modal.modalright
          variant="primary"
          size="lg"
          @click.self="markAllAsRead()">
          Mark all as read
        </b-button>
      </template>
    </datatable-heading>

    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="horizonNotificationID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded">
              <template slot="notificationText" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                <b-link tag="a" :to="props.rowData.notificationURL">
                  {{props.rowData.notificationText}}
                </b-link>
                </div>
              </template>
              <template slot="actions" slot-scope="props">
                <div style="display:flex;justify-content:center;align-text;center;">
                  <div class="activeDot" v-if="props.rowData.active" @click="updateNotificationAndReload(props.rowData, 'read')">x</div>
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>

    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction(currentSelectedItem && currentSelectedItem.active ? 'read' : 'unread')">
        <span>{{(currentSelectedItem && currentSelectedItem.active) ? 'Mark as read' : 'Unread'}}</span>
      </v-contextmenu-item>
    </v-contextmenu>

    <NotificationConfigureModal ref="NotificationConfigureModalRef"></NotificationConfigureModal>
  </div>
</template>
<style scoped>

</style>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../constants/config'
import Confirmation from '../../../components/Common/Confirmation.vue'
import moment from 'moment/moment.js'
import NotificationConfigureModal from '../../../components/Modals/NotificationConfigureModal.vue'

import DatatableHeading from '../../../containers/datatable/DatatableHeading.vue'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'

// so we can set our messages in the layout if needed
import { mapMutations } from 'vuex'

import NotificationMixin from '../../../mixins/NotificationMixin'

export default {
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: Confirmation,
    NotificationConfigureModal: NotificationConfigureModal
  },
  mixins: [NotificationMixin],
  data () {
    var _idfield = 'horizonNotificationID'
    return {
      employeeID: localStorage.getItem('employeeID'),
      isLoad: false,
      sort: '',
      page: 1,
      perPage: 25,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Notification ID',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'number'
        },
        {
          name: 'notificationType',
          sortField: 'notificationType',
          title: 'Type',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'text'
        },
        {
          name: '__slot:notificationText',
          sortField: 'notificationText',
          title: 'Notification Contents',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'text'
        },
        {
          name: 'dateCreatedHumanized',
          sortField: 'dateCreated',
          title: 'Created',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'date'
        },
        {
          name: 'dateAcknowledgedHumanized',
          sortField: 'dateAcknowledged',
          title: 'Acknowledged',
          titleClass: '',
          dataClass: 'text-muted',
          filterType: 'date'
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'text-center',
          dataClass: ''
        }
      ],
      currentSelectedItem: null
    }
  },
  computed: {
    apiBase () {
      return BeaconapiUrl + `/api/notification/${this.employeeID}/table`
    }
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].sortField + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (dataItem.active) {
        outputclass = 'active-notification-row'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      this.currentSelectedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    async onContextMenuAction (action) {
      switch (action) {
        case 'read':
        {
          await this.updateNotificationAndReload(this.rightClickedItem, 'read')
          break
        }
        case 'unread':
        {
          await this.updateNotificationAndReload(this.rightClickedItem, 'unread')
          break
        }
        default:
          break
      }
    },
    async updateNotificationAndReload (notification, status) {
      await this.updateNotification(notification, status)
      await this.refreshVueTable()
    },

    // End Component-specific Vue Table methods
    async markAllAsRead () {
      await this.$refs.Confirmation.show({
        title: 'Mark All Notifications Read',
        message: 'Are you sure you want to mark all notifications as read?',
        okButton: 'Confirm'
      }).then(async (result) => {
        if (result) {
          await this.acknowledgeAllNotificationsForUser(this.employeeID)
          await this.refreshVueTable()
        }
      })
    },
    notificationConfigure () {
      this.$refs.NotificationConfigureModalRef.show({
        exitFunction: async () => {
          this.refreshVueTable()
        }
      })
    }
  }
}
</script>
